import ENV from 'constants/environments';
import { HomeMetaData } from 'containers/home/metadata';
import { PageHeadLinksInterface } from 'interfaces/page.interface';
import Head from 'next/head';
import { FC } from 'react';

const MetaTags: FC<PageHeadLinksInterface> = ({
  title = '',
  description = '',
  robots,
  canonical = '',
  viewport,
  children,
}) => {
  return (
    <Head key={title}>
      {children ? children : null}
      {title ? (
        <>
          <meta name="twitter:title" content={title} />
          <meta property="og:title" content={title} />
          <meta name="apple-mobile-web-app-title" content={title} />

          <title>{title}</title>
        </>
      ) : (
        <>
          <meta
            name="apple-mobile-web-app-title"
            content={HomeMetaData.title}
          />

          <meta name="twitter:title" content={HomeMetaData.title} />
          <meta property="og:title" content={HomeMetaData.title} />
          <title>{HomeMetaData.title}</title>
        </>
      )}
      {description ? (
        <>
          <meta name="description" content={description} />
          <meta name="twitter:description" content={description} />
          <meta property="og:description" content={description} />
        </>
      ) : (
        <>
          <meta name="description" content={HomeMetaData.description} />
          <meta name="twitter:description" content={HomeMetaData.description} />
          <meta property="og:description" content={HomeMetaData.description} />
        </>
      )}
      {viewport ? <meta name="viewport" content={viewport} /> : null}
      {robots ? (
        ENV.APP_ENV.includes('test') ? (
          <meta name="robots" content="NOINDEX,NOFOLLOW" />
        ) : (
          <meta name="robots" content={robots} />
        )
      ) : null}
      {canonical ? (
        <>
          <link rel="canonical" href={canonical} />
          <meta name="twitter:url" content={canonical} />
          <meta property="og:url" content={canonical} />
        </>
      ) : (
        <>
          <link rel="canonical" href={HomeMetaData.canonical} />
          <meta name="twitter:url" content={HomeMetaData.canonical} />
          <meta property="og:url" content={HomeMetaData.canonical} />
        </>
      )}
    </Head>
  );
};

export default MetaTags;
