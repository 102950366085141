import ENV from 'constants/environments';

export const HomeMetaData = {
  title: 'کارنامه؛ پلتفرم آنلاین خدمات خودرو',
  description:
    'کارنامه مرجع تخصصی خدمات خودرو و موتورسیکلت مانند کارشناسی، خرید و فروش، خرید قسطی، قیمت‌گذاری آنلاین و سرویس‌های دیجیتال نظیر مبایعه‌نامه، استعلام خلافی و گزارش قیمت.',
  canonical: `${ENV.LANDING_URL}/`,
  jsonLd: {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Corporation',
        '@id': 'https://karnameh.com/#corporation',
        name: 'کارنامه',
        alternateName: ['کارنامه', 'Karnameh'],
        legalName: 'خودرو پردازش هوشمند',
        url: 'https://karnameh.com/',
        logo: 'https://karnameh.com/_next/static/media/karnameh-logo-new.e1d28f30.svg',
        email: 'info@karnameh.com',
        sameAs: [
          'https://www.youtube.com/@karnameh_com',
          'https://www.aparat.com/Karnameh.com',
          'https://t.me/karnameh_com',
          'https://www.linkedin.com/company/karnameh/',
          'https://twitter.com/karnameh_com',
          'https://www.instagram.com/karnameh.official/',
        ],
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: '+982179237000',
          contactType: 'customer service',
          areaServed: 'IR',
          availableLanguage: 'Persian',
        },
        address: {
          '@type': 'PostalAddress',
          streetAddress: 'Tehran Province, Tehran, District 3, Emdad-e-Gharbi',
          addressLocality: 'Tehran, Iran',
          addressCountry: {
            '@type': 'Country',
            name: 'Iran',
          },
          hasMap: 'https://maps.app.goo.gl/WqwNAUgUEvaq59g17',
        },
        founders: [
          {
            '@type': 'Person',
            image:
              'https://worldfellows.yale.edu/wp-content/uploads/2022/08/Nima-Namdari-800x431.jpg',
            jobTitle: 'Chief executive officer',
            name: 'Nima Namdari',
            sameAs: [
              'https://ir.linkedin.com/in/nima-namdari',
              'https://worldfellows.yale.edu/person/nima-namdari/',
            ],
          },
        ],
      },
    ],
  },
};
